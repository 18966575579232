import { navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import AuthCard, { AuthCardType } from "../../components/dashboard/AuthCard"
import { checkUserExists } from "../../store/actions/authActions"
import { authStateInterface } from "../../store/reducers/authReducer"
import { utilsStateInterface } from "../../store/reducers/utilsReducer"

interface Props {
  authStore: authStateInterface
  utilsStore: utilsStateInterface
}

class SignIn extends Component<Props> {
  state = {
    page: AuthCardType.SIGNIN,
  }

  componentDidMount() {
    this.handleCheckAuthNavigator()
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth
    ) {
      this.handleCheckAuthNavigator()
    }
  }

  handleCheckAuthNavigator = async () => {
    if (this.props.authStore.userAuth) {
      const userExists = await checkUserExists(this.props.utilsStore.firebase)
      if (!userExists) {
        navigate("/auth/signUp")
      } else {
        navigate("/dashboard")
      }
    }
  }

  handleChangePage = (page: AuthCardType) => {
    this.setState({
      page,
    })
  }

  render() {
    if (this.props.authStore.userLoading) {
      return null
    }

    return (
      <div className="bg-white">
        <SEO
          title="Arus Oil - Sign In"
          description="Sign in an account in Arus Oil now. This allows you to view daily purchase rates of used cooking oil,contact logistic for collection and view payment made to you."
        />
        <Header page="Sign In" />
        <main className="overflow-hidden">
          <div className="bg-warm-gray-50">
            <div className="py-16">
              <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl">
                  Sign In
                </h1>
              </div>
            </div>
          </div>

          <section
            className="relative bg-white"
            aria-labelledby="contactHeading"
          >
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <svg
                className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-warm-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>
            <div className="pb-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <AuthCard
                type={this.state.page}
                handleChangePage={this.handleChangePage}
              />
            </div>
          </section>
        </main>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(SignIn)
